<template>
  <div class="login-container">
    <div class="count_login" v-show="account">
      <!-- <el-image
        class="login_img"
        :src="require('@/assets/er_code.png')"
        @click="show_login"
        fit="cover"
      ></el-image> -->
      <el-form
        :model="loginForm"
        :rules="rules"
        ref="loginForm"
        class="login-form"
        auto-complete="off"
        label-position="left"
      >
        <div class="title-container">
          <h3 class="title">商盈管理系统</h3>
        </div>

        <el-form-item prop="username">
          <span class="svg-container">
            <i class="el-icon-user-solid" />
          </span>
          <el-input
            ref="username"
            v-model="loginForm.username"
            placeholder="请输入账号"
            name="username"
            type="text"
            tabindex="1"
            auto-complete="off"
          />
        </el-form-item>

        <el-form-item prop="password">
          <span class="svg-container">
            <i class="el-icon-lock" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="请输入密码"
            name="password"
            tabindex="2"
            auto-complete="off"
            @keyup.enter.native="handleLogin"
          />
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="14">
            <el-form-item prop="validateCode" style="padding-left:10px">
              <span class="svg-container">
                <i class="el-icon-key" />
              </span>
              <el-input
                v-model="loginForm.validateCode"
                placeholder="请输入验证码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <validate-code
              ref="ref_validateCode"
              class="codeimg"
              @change="changeCode"
            />
          </el-col>
        </el-row>
        <el-button
          type="primary"
          style="width: 100%; height: 60px; font-size: 24px; margin-top: 5px"
          @click.native.prevent="handleLogin"
          :loading="isLoading"
          >登 录</el-button
        >
      </el-form>
    </div>
    <!-- <div class="chg_login" v-show="ercode">
      <el-image
        class="login_img"
        :src="require('@/assets/count.png')"
        @click="show_login"
        fit="cover"
      ></el-image>
      <div id="ding_login" class="ding_login">这里显示二维码</div>
    </div> -->
  </div>
</template>

<script>
import userApi from "../api/user";
import qs from "qs";
import { Alert } from "element-ui";
import validateCode from "../views/ValidateCode";
// let AppKey = "dinguxl7aomv4c2yu8ot"; //公司的AppKey
// let AppSecret =
//   "FrG2X1aW2mAANYe6XHeUe20uhmU7FmWrB3A0yCmPm_H8cTjvOgfi2wVXnhw18ueb"; //公司的密钥
// let redirect_uri = encodeURIComponent("http://5v03c02377.zicp.vip/login");

// let http_url = encodeURIComponent(
//   `https://login.dingtalk.com/oauth2/auth?redirect_uri=${redirect_uri}&response_type=code&client_id=${AppKey}&scope=openid&state=dddd&prompt=consent`
// );
// let http_url = encodeURIComponent(
//   `https://oapi.dingtalk.com/connect/qrconnect?appid=${AppKey}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${redirect_uri}`
// );
export default {
  components: {
    validateCode,
  },
  data() {
    return {
      isLoading: false,
      codeimg: "",
      authCode: "",
      account: true,
      ercode: false,
      passwordType: "password",
      // code: qs.parse(window.location.hash.substring(3)).code, // 获取的临时登陆码
      fits: ["fill", "contain", "cover", "none", "scale-down"],
      loginForm: {
        username: "",
        password: "",
        validateCode: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      checkCode: "",
    };
  },
  created() {
    // let url = location.href.split("#")[0];
    // console.log(url)
    localStorage.setItem("shangytoken", "");
  },
   mounted(){
    //绑定键盘监听事件
    window.addEventListener('keydown',this.keyDown)
  },
  destroyed(){
    window.removeEventListener('keydown',this.keyDown,false)
  },
  methods: {
     keyDown(e){
      if(e.keyCode==13){
        this.handleLogin();
      }
    },
    changeCode(value) {
      this.checkCode = value;
    },
    handleLogin() {
      if (this.loginForm.validateCode.toUpperCase() === this.checkCode) {
        console.log("验证成功");
      } else {
        this.$message({
          showClose: true,
          message: "验证码错误",
          type: "error",
        });
        this.loginForm.validateCode = "";
        this.$refs["ref_validateCode"].draw();
        return;
      }
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.isLoading = true;
          userApi
            .login(this.loginForm)
            .then((res) => {
              if (res.data.code !== 200) {
                this.$notify({
                  title: "错误",
                  message: res.data.message,
                  position: "bottom-right",
                  type: "error",
                });
                return;
              }
              const tokenStr = res.headers["authorization"];
              this.$store.commit("SET_TOKEN", tokenStr);
              this.$router.replace("/index");
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  background-color: #6d849c;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .count_login {
    position: relative;
    background-color: #ffffff;
    width: 480px;
    height: 450px;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 2px rgb(78, 75, 75);
    .login-form {
      position: relative;
      width: 400px;
      max-width: 100%;
      margin: 0 auto;
      overflow: hidden;
    }
    .svg-container {
      color: #889aa4;
    }
    .title-container {
      position: relative;
      .title {
        font-size: 26px;
        color: rgb(78, 75, 75, 0.8);
        margin: 30px auto;
        text-align: center;
        font-weight: bold;
      }
    }
    .show-pwd {
      position: absolute;
      right: 10px;
      top: 7px;
      font-size: 16px;
      color: #889aa4;
      cursor: pointer;
      user-select: none;
    }
  }
  .chg_login {
    width: 480px;
    height: 380px;
    background-color: #ffffff;
    position: relative;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 2px rgb(78, 75, 75);
    .login_img {
      position: absolute;
      width: 80px;
      height: 80px;
      top: 0;
      right: 0;
      &:hover {
        cursor: pointer;
      }
    }
    .ding_login {
      width: 400px;
      height: 400px;
      margin: 40px auto;
    }
  }
}
</style>
<style lang="scss">
$bg: #283443;
$light_gray: #283443;
$cursor: #283443;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .count_login .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.count_login {
  .el-input {
    display: inline-block;
    height: 60px;
    width: 85%;
    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 60px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #f0f0f0 inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(7, 6, 6, 0.055);
    background: rgba(110, 106, 106, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>
<style scoped lang="scss">
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: rgba(110, 106, 106, 0.1);
}
.codeimg {
  height: 60px;
  width: 100%;
  line-height: 60px;
  border-radius: 5px;
}
.grid-content {
  border-radius: 5px;
  min-height: 55px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>

